import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1625e1ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "copy" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('legal.hero.title')), 1),
        _createElementVNode("p", {
          innerHTML: _ctx.$t('legal.hero.text')
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('legal.content'), (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (item['type'] == 'textArr')
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item['text'], (text, j) => {
                  return (_openBlock(), _createElementBlock("p", {
                    innerHTML: text,
                    key: j
                  }, null, 8, _hoisted_6))
                }), 128))
              : _createCommentVNode("", true),
            (item['type'] == 'p')
              ? (_openBlock(), _createElementBlock("p", {
                  innerHTML: item['text'],
                  key: i
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            (item['type'] == 'ul')
              ? (_openBlock(), _createElementBlock("ul", { key: i }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item['items'], (list, j) => {
                    return (_openBlock(), _createElementBlock("li", { key: j }, [
                      _createElementVNode("span", {
                        innerHTML: (list as any).text
                      }, null, 8, _hoisted_8),
                      ((list as any).sublist)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((list as any).sublist, (sublist, k) => {
                              return (_openBlock(), _createElementBlock("li", { key: k }, [
                                _createElementVNode("span", { innerHTML: sublist }, null, 8, _hoisted_10)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (item['type'] == 'ol')
              ? (_openBlock(), _createElementBlock("ol", {
                  key: i,
                  class: _normalizeClass(item['class'])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item['items'], (list, j) => {
                    return (_openBlock(), _createElementBlock("li", { key: j }, [
                      _createElementVNode("span", {
                        innerHTML: (list as any).text
                      }, null, 8, _hoisted_11),
                      ((list as any).sublist)
                        ? (_openBlock(), _createElementBlock("ol", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((list as any).sublist[0].items, (sub, k) => {
                              return (_openBlock(), _createElementBlock("li", { key: k }, [
                                _createElementVNode("span", {
                                  innerHTML: sub.text
                                }, null, 8, _hoisted_13),
                                ((sub as any).sublist)
                                  ? (_openBlock(), _createElementBlock("ol", _hoisted_14, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((sub as any).sublist, (subtwo, l) => {
                                        return (_openBlock(), _createElementBlock("li", { key: l }, [
                                          _createElementVNode("span", { innerHTML: subtwo }, null, 8, _hoisted_15)
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ])
    ])
  ]))
}