
    import { Vue } from 'vue-class-component';
    // import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

    
    export default class Modal extends Vue {
        isActive = false
        video={
            id:"6319911984112",
            acc:"4317630935001"
        }
        
        lastScroll = 0;

        disableScroll() {
            document.addEventListener('touchmove', this.preventScroll, { passive: false });
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "100vh";
            disableScroll.style.overflowY = "hidden";
        }
        enableScroll() {
            document.removeEventListener('touchmove', this.preventScroll);
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "auto";
            disableScroll.style.overflowY = "auto";
        }
        preventScroll(event: Event) {
            event.preventDefault();
        }
        open(){
            this.isActive = true
            const body = document.querySelector('body') as HTMLElement
            body.style.overflowY = 'hidden';
            this.disableScroll();
            this.video={
                id:"6319911984112",
                acc:"4317630935001"
            }
            const extJS = document.createElement("script");
            extJS.setAttribute("src", "//players.brightcove.net/4317630935001/default_default/index.min.js");
            extJS.setAttribute("id", "ext-player");
            extJS.async = true;
            document.head.appendChild(extJS);
            const contentVideo = document.querySelector('.text-content.video') as HTMLElement
            contentVideo.innerHTML=`
                <video-js data-account="${this.video.acc}"
                    data-player="default"
                    data-embed="default"
                    class="video-js"
                    controls=""
                    data-video-id="${this.video.id}"
                    data-playlist-id=""
                    data-application-id=""
                    data-setup='{"fluid": true}'>
                </video-js>`
        }
        close(){
            this.isActive = false
            this.enableScroll();
            const contentVideo = document.querySelector('.text-content.video') as HTMLElement
            const extPlayer = document.querySelector('#ext-player') as HTMLElement
            const body = document.querySelector('body') as HTMLElement
            body.style.overflowY = 'scroll';
            if(extPlayer){
                extPlayer.remove()
            }
            contentVideo.innerHTML=""
        }

    }
    
