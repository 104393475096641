
import { Vue, Options } from 'vue-class-component';
import Modal from '@/components/Modal.vue'
@Options({
    components: {
        Modal
    }
})
export default class BlueCard extends Vue {
    declare $refs: {
        modal: Modal
    }
}
