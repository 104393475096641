
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';

@Options({
    components: {

    },

})
export default class privacyView extends Vue {
    meta = setup(() => useMeta(
        computed(() => ({
            title: 'PAH Companion withMe | Official PAHC Website',
            description: 'PAH Companion withMe | Official PAHC Website'
        }))
    ))
}
