
    import { Vue } from 'vue-class-component';
    export default class Modal extends Vue {
        isActive = false
        url:string | undefined = ''

        disableScroll() {
            document.addEventListener('touchmove', this.preventScroll, { passive: false });
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "100vh";
            disableScroll.style.overflowY = "hidden";
        }
        enableScroll() {
            document.removeEventListener('touchmove', this.preventScroll);
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "auto";
            disableScroll.style.overflowY = "auto";
        }
        preventScroll(event: Event) {
            event.preventDefault();
        }
        open(e:Event){
            const url = e.target as HTMLElement
            this.disableScroll()

            this.url = url.dataset.url            
            if (url.getBoundingClientRect().top > 200) {
                this.$el.setAttribute('style',`top:${url.offsetTop - 140}px;left:${url.offsetLeft - 10}px`)
            } else {
                this.$el.setAttribute('style',`top:${url.offsetTop + 40}px;left:${url.offsetLeft - 10}px`)
            }
            this.isActive = true
        }
        close(){
            this.isActive = false
            this.enableScroll()
        }
    }
