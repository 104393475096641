
import { Vue, Options } from 'vue-class-component';
import Modal from '@/components/Modal.vue'

@Options({
    components: {
        Modal
    },
    watch:{
        $route(){
            this.$refs.modal.close()
        }
    }
})
export default class Header extends Vue {
    isActive = false;
    declare $refs: {
    modal: Modal
    }
}
