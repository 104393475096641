import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'
import PahCompanionView from '@/views/pah-companion/PahCompanionView.vue'
import NonPatients from '@/views/non-patients/nonPatients.vue'
import SavedContacts from '@/views/saved-contacts/SavedContacts.vue'
import LegalView from '@/views/legal/LegalView.vue'
import PrivacyView from '@/views/privacy/PrivacyView.vue'
import CookieView from '@/views/cookies/CookiesView.vue'
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pah-companion/:tab?',
    name: 'Pah Companion',
    component: PahCompanionView
  },
  {
    path: '/non-patients',
    name: 'Non patients',
    component: NonPatients
  },
  {
    path: '/savedcontacts',
    name: 'Saved Contacts',
    component: SavedContacts
  },
  {
    path: '/legal-notice',
    name: 'Legal Notice',
    component: LegalView
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyView
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: CookieView
  }
]

const router = createRouter({
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL)
})

export default router
