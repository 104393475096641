
import { Vue, Options } from 'vue-class-component';
@Options({
    props: {
        id: '',
        max: '',
        title: '',
        download: '',
        text: '',
        img: ''
    }
})
export default class Quotes extends Vue {
    id!: number
    max!: number
    title!: string
    download!: string
    text!: string
    img!: string
}
