
    import { computed } from '@vue/runtime-core'
    import { Vue, setup } from 'vue-class-component';
    import { useMeta } from 'vue-meta';


    export default class SavedContacts extends Vue {
        meta = setup(() => useMeta(
            computed(() => ({
                title: 'PAH Companion withMe | Saved Contacts',
                description: 'PAH Companion withMe | Saved Contacts'
            }))
        ))
    }
