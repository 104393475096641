import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "organizations__cards__card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["data-url"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalToExternal = _resolveComponent("ModalToExternal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalToExternal, { ref: "modal" }, null, 512),
    _createElementVNode("img", {
      src: require('@/assets/images/' + _ctx.img),
      alt: "",
      loading: "lazy",
      style: _normalizeStyle({width: _ctx.imgWidth + 'px'})
    }, null, 12, _hoisted_2),
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
    _createElementVNode("a", {
      "data-url": _ctx.linkHref,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.modal.open($event))),
      target: "_blank"
    }, _toDisplayString(_ctx.link), 9, _hoisted_3)
  ]))
}