
    import { Vue } from 'vue-class-component';
    export default class ModalToExternal extends Vue {
        isActive = false
        url:string | undefined = ''
        open(e:Event){
            const url = e.target as HTMLElement           

            this.url = url.dataset.url
            if (url.getBoundingClientRect().top > 200) {
                this.$el.setAttribute('style',`top:${url.offsetTop - 140}px;left:${url.offsetLeft - 10}px`)
            } else {
                this.$el.setAttribute('style',`top:${url.offsetTop + 40}px;left:${url.offsetLeft - 10}px`)
            }
            this.isActive = true

        }
        close(){
            this.isActive = false
        }
    }
