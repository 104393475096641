import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87fbfab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "copy" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML", "id"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('privacy.hero.title')), 1),
        (_ctx.$t('privacy.hero.link.text'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.$t('privacy.hero.link.link')
            }, _toDisplayString(_ctx.$t('privacy.hero.link.text')), 9, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          innerHTML: _ctx.$t('privacy.hero.text')
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('privacy.content'), (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (item['type'] == 'textArr')
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item['text'], (text, j) => {
                  return (_openBlock(), _createElementBlock("p", {
                    innerHTML: text,
                    key: j
                  }, null, 8, _hoisted_7))
                }), 128))
              : _createCommentVNode("", true),
            (item['type'] == 'p')
              ? (_openBlock(), _createElementBlock("p", {
                  innerHTML: item['text'],
                  class: _normalizeClass(item['class']),
                  id: item['id'],
                  key: i
                }, null, 10, _hoisted_8))
              : _createCommentVNode("", true),
            (item['type'] == 'ul')
              ? (_openBlock(), _createElementBlock("ul", { key: i }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item['items'], (list, j) => {
                    return (_openBlock(), _createElementBlock("li", { key: j }, [
                      _createElementVNode("span", {
                        innerHTML: (list as any).text
                      }, null, 8, _hoisted_9),
                      ((list as any).textarr)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((list as any).textarr, (arr, idx) => {
                            return (_openBlock(), _createElementBlock("p", {
                              key: idx,
                              innerHTML: arr
                            }, null, 8, _hoisted_10))
                          }), 128))
                        : _createCommentVNode("", true),
                      ((list as any).sublist)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((list as any).sublist, (sublist, k) => {
                              return (_openBlock(), _createElementBlock("li", { key: k }, [
                                (!sublist['text'])
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      innerHTML: sublist
                                    }, null, 8, _hoisted_12))
                                  : _createCommentVNode("", true),
                                (sublist['text'])
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 1,
                                      innerHTML: sublist['text']
                                    }, null, 8, _hoisted_13))
                                  : _createCommentVNode("", true),
                                ((sublist as any).sublist)
                                  ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((sublist as any).sublist, (subtwo, l) => {
                                        return (_openBlock(), _createElementBlock("li", { key: l }, [
                                          _createElementVNode("span", { innerHTML: subtwo }, null, 8, _hoisted_15)
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                (sublist['botText'])
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 3,
                                      innerHTML: sublist['botText']
                                    }, null, 8, _hoisted_16))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (item['type'] == 'ol')
              ? (_openBlock(), _createElementBlock("ol", {
                  key: i,
                  class: _normalizeClass(item['class'])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item['items'], (list, j) => {
                    return (_openBlock(), _createElementBlock("li", { key: j }, [
                      _createElementVNode("span", {
                        innerHTML: (list as any).text
                      }, null, 8, _hoisted_17),
                      ((list as any).sublist)
                        ? (_openBlock(), _createElementBlock("ol", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((list as any).sublist[0].items, (sub, k) => {
                              return (_openBlock(), _createElementBlock("li", { key: k }, [
                                _createElementVNode("span", {
                                  innerHTML: sub.text
                                }, null, 8, _hoisted_19),
                                ((sub as any).sublist)
                                  ? (_openBlock(), _createElementBlock("ol", _hoisted_20, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((sub as any).sublist, (subtwo, l) => {
                                        return (_openBlock(), _createElementBlock("li", { key: l }, [
                                          _createElementVNode("span", { innerHTML: subtwo }, null, 8, _hoisted_21)
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ])
    ])
  ]))
}