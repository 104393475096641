
    import {Vue} from 'vue-class-component'
    export default class Footer extends Vue{
        mounted(){
            document.addEventListener('DOMContentLoaded', ()=>{

                let sprivacyLink = document.querySelector('#phmFooterContent .subpages') as HTMLElement;
                let slegalLink = document.querySelector('#phmFooterContent .subpages') as HTMLElement;

                if (sprivacyLink && slegalLink) {
                    sprivacyLink = sprivacyLink.firstElementChild!.firstElementChild as HTMLElement;
                    slegalLink = slegalLink.lastElementChild!.firstElementChild as HTMLElement;

                    sprivacyLink.setAttribute("href","/privacy-policy")
                    slegalLink.setAttribute("href","/legal-notice")
                    
                    sprivacyLink.addEventListener('click',(e)=>{
                        e.preventDefault()
                        this.$router.push('/privacy-policy')
                    })
                    slegalLink.addEventListener('click',(e)=>{
                        e.preventDefault()
                        this.$router.push('/legal-notice')
                    })
                }            
            })     
        }
    }
