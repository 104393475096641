
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'
  import { Vue, Options } from 'vue-class-component'
  @Options({
    components:{
      Header,
      Footer,
    }
  })
  
  export default class App extends Vue{
    mounted() {
      const body = document.querySelector('body') as HTMLElement;
      let elToObserveAttr = document.querySelector('#onetrust-banner-sdk') as HTMLElement;
      let elToChangeAttr = document.querySelector('.onetrust-pc-dark-filter') as HTMLElement;
      const handleChangeAttr = () => {
        elToChangeAttr.style.display = 'none';
      };
      const observerAttr = new MutationObserver(handleChangeAttr);
      const handleElementAdded = () => {
        elToObserveAttr = document.querySelector('#onetrust-banner-sdk') as HTMLElement;
        if (elToObserveAttr) {
          observerAttr.observe(elToObserveAttr, {attributes: true});
          observerElementAdded.disconnect();
        }
      };
      const observerElementAdded = new MutationObserver(handleElementAdded)
      const configElementAdded = {
        childList: true
      }
      observerElementAdded.observe(body, configElementAdded)
    }
  }
