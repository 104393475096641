import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e65abde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "blue__box" }
const _hoisted_3 = { class: "blue__box__row" }
const _hoisted_4 = { class: "blue__box__row__item" }
const _hoisted_5 = { class: "blue__box__row__item" }
const _hoisted_6 = { class: "blue__box__last" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Modal, { ref: "modal" }, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$tm('home.section2.blueBox.h3')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            "data-url": "https://www.pahcompanion.com/",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.modal.open($event))),
            class: "btn"
          }, _toDisplayString(_ctx.$tm("home.section2.blueBox.button.content")), 1),
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$tm('home.section2.blueBox.logIn')) + " ", 1),
            _createElementVNode("a", {
              "data-url": "https://www.pahcompanion.com/",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.modal.open($event))),
              target: "_blank",
              class: "font-white"
            }, _toDisplayString(_ctx.$tm("home.section2.blueBox.logInA")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$tm('home.section2.blueBox.p')), 1),
          _createVNode(_component_router_link, {
            to: '/non-patients',
            href: '/non-patients',
            class: "font-white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tm('home.section2.blueBox.moreInfo.aText')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$tm('home.section2.blueBox.last')), 1)
    ])
  ]))
}