
import { Vue, Options } from 'vue-class-component';
import ModalToExternal from '@/components/ModalToExternal.vue'

@Options({
    props: {
        img: '',
        imgWidth: '',
        title: '',
        text: '',
        link: '',
        linkHref: ''
    },
    components: {
        ModalToExternal
    }
})

export default class Quotes extends Vue {
    declare $refs: {
    modal: ModalToExternal
    }
    img!: string
    imgWidth!: number
    title!: string
    text!: string
    link!: string
    linkHref!: string
}
