
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper';


import 'swiper/css';
import 'swiper/css/navigation';


import Hero from './sections/Hero.vue';
import Tabs from '@/components/Tabs.vue';
import Quotes from '@/components/Quotes.vue'
import QuotesImg from '@/components/QuotesImg.vue'
import QuotesDownload from '@/components/QuotesDownload.vue'
import SectionImage from '@/components/SectionImage.vue'
import Brand from '@/components/Brand.vue'
import ModalToExternal from '@/components/ModalToExternal.vue';


@Options({
    components: {
        Hero,
        Tabs,
        Swiper,
        SwiperSlide,
        Quotes,
        QuotesImg,
        QuotesDownload,
        Brand,
        SectionImage,
        ModalToExternal
    }
})
export default class PahCompanionView extends Vue {
    tabList = ["Visión general", "Recursos para pacientes", "Historias de pacientes", "Programar una conversación"]
    modules = [Pagination, Navigation]
    max = 3
    isHide = false
    swiperBreakpoints = {
        480: {
            slidesPerView: 0.9,
            spaceBetween: 15
        },
        640: {
            slidesPerView: 1.4,
            spaceBetween: 30
        },
        1100: {
            slidesPerView: 2.4,
            spaceBetween: 50
        },
        2000: {
            slidesPerView: 3,
            spaceBetween: 50
        }
    }

    meta = setup(() => useMeta(
        computed(() => ({
            title: 'PAH Companion withMe | Official PAHC Website',
            description: 'PAH Companion withMe | Official PAHC Website'
        }))
    ))
}
