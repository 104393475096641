
import { Vue, Options } from 'vue-class-component';
import ModalVideo from '@/components/ModalVideo.vue'
@Options({
    components: {
        ModalVideo
    },
    props: {
        title: '',
        img: '',
        imgRight: false,
        text: '',
        linkText: '',
        linkHref: '',
        linkImg: '',
        bgColor: '',
        small: '',
        extLink:'',
        noPadBot: false,
        videoClick: false
    }
})
export default class SectionImage extends Vue {
    title!: string
    img!: string
    imgRight!: boolean
    text!: string
    linkText!: string
    linkHref!: string
    linkImg!: string
    bgColor!: string
    small!: string
    noPadBot!: boolean
    videoClick!: boolean
    extLink!: string

    declare $refs: {
        modalVideo: ModalVideo
    }

}
