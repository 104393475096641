import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "non-patients" }
const _hoisted_2 = { class: "container cont-non" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_SectionImage = _resolveComponent("SectionImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Hero),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SectionImage, {
        title: 'Su voz es su poder',
        text: `En la lucha contra la hipertensión arterial pulmonar (HAP), su voz es importante. Aprender más sobre la HAP puede ayudarle a hacerse oír y a colaborar con su proveedor de atención médica. Porque cuando uno intercede por sí mismo, saca el máximo provecho de sus visitas al médico.`,
        img: 'sectionImage9.jpg',
        linkText: 'Más información sobre Breathe In, Speak Out',
        extLink: 'https://www.pahbreatheinspeakout.com/',
        linkImg: 'arrow.svg',
        class: "sectionImage9"
      }, null, 8, ["text", "img", "extLink", "linkImg"]),
      _createVNode(_component_SectionImage, {
        title: 'Únase a nuestra comunidad',
        text: `Inscríbase hoy mismo para recibir información y recursos educativos a fin de obtener la asistencia y las habilidades que necesita para comenzar su viaje con la HAP. Recibirá información sobre la HAP, formas de colaborar con su médico y abogar por su propia atención, y mucho más, directamente en su bandeja de entrada. Solo le llevará un minuto o dos.`,
        img: 'sectionImage10.jpg',
        linkText: 'Inscríbase ahora',
        extLink: 'https://www.pahbreatheinspeakout.com/#community',
        linkImg: 'arrow.svg',
        imgRight: true,
        class: "sectionImage10"
      }, null, 8, ["text", "img", "extLink", "linkImg"])
    ])
  ]))
}