
    import { computed } from '@vue/runtime-core'
    import { Vue, setup } from 'vue-class-component';
    import { useMeta } from 'vue-meta';


    export default class CookiePolicy extends Vue {
        meta = setup(() => useMeta(
            computed(() => ({
                title: 'PAH Companion withMe | Cookie Policy',
                description: 'PAH Companion withMe | Cookie Policy'
            }))
        ))
    }
