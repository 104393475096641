import { Locales } from "./locales";

import homeEs from "@/data/es/views/home.json"
import pahCompanionViewEs from "@/data/es/views/pahCompanionView.json"
import legal from '@/data/es/views/legal.json'
import privacy from '@/data/es/views/privacy.json'

const es = {
  home: homeEs,
  pahCompanionView: pahCompanionViewEs,
  legal,
  privacy
}
export const messages = {
  [Locales.ES]: es
};

export const defaultLocale = Locales.ES;