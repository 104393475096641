
import { Vue, Options } from 'vue-class-component';
@Options({
    props:{
        tabList:Array
    }
})
export default class Tabs extends Vue {
    tabList!: Array<string>
    activeTab = 1
    changeTab(idx:number){
        idx + 1 < this.tabList.length ? this.activeTab = idx + 1 : window.open("https://scheduler.pahcompanion.com/es","_blank")
    }
    mounted(){
        let params = parseInt(this.$route.params.tab.toString())
        if(this.$route.params.tab) this.changeTab(params - 1)
    }
}


