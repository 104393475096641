import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "quote" }
const _hoisted_2 = { class: "quote__up" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "quote__down" }
const _hoisted_6 = { class: "quote__author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title != undefined)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: "quote__text",
        innerHTML: _ctx.text
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.author), 1)
    ])
  ]))
}