import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    (_ctx.$route.name !== 'Saved Contacts')
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.$route.name !== 'Saved Contacts')
      ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}