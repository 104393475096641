
    import { Vue } from 'vue-class-component';
    export default class MainModal extends Vue {
        isActive = true
        disableScroll() {
            // document.addEventListener('touchmove', this.preventScroll, { passive: false });
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "100vh";
            disableScroll.style.overflowY = "hidden";
        }
        enableScroll() {
            // document.removeEventListener('touchmove', this.preventScroll);
            const disableScroll = document.querySelector("body") as HTMLElement;
            disableScroll.style.height = "auto";
            disableScroll.style.overflowY = "auto";
        }
        preventScroll(event: Event) {
            event.preventDefault();
        }
        beforeMount() {
            window.addEventListener('keyup', this.onKey)
        }
        mounted() {
            this.disableScroll();
        }
        beforeDestroy () {
            window.removeEventListener('keyup', this.onKey)
        }
        open(){
            this.isActive = true
        }
        close(){
            this.isActive = false
            this.enableScroll();
        }
        onKey(evt:KeyboardEvent){
            if (evt.which === 27) {
                this.close()
            }
        }
    }
