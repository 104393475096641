import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quoteImg" }
const _hoisted_2 = { class: "quoteImg__icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "quoteImg__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/images/' + _ctx.img),
        alt: "",
        loading: "lazy"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}