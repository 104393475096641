
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';
import Hero from '@/views/home/sections/Hero.vue';
import BlueCard from '@/views/home/sections/BlueCard.vue'
import SectionImage from '@/components/SectionImage.vue'
import MainModal from '@/components/MainModal.vue'
import Modal from '@/components/Modal.vue'
import { global } from '@/utils/globalReactives'

@Options({
    components: {
        Hero,
        BlueCard,
        SectionImage,
        MainModal,
        Modal
    },
    watch:{
        $route(){
            global.isFirstHome = false
        }
    }
})
export default class HomeView extends Vue {
    declare $refs: {
        modal: Modal
    }
    firstView = global.isFirstHome
    mounted(){
        //console.log(global.isFirstHome)
    }
    meta = setup(() => useMeta(
        computed(() => ({
            title: 'PAH Companion withMe | Official PAHC Website',
            description: 'PAH Companion withMe | Official PAHC Website'
        }))
    ))
}
