
import { Vue, Options } from 'vue-class-component';
@Options({
    props: {
        title: '',
        text: '',
        img: ''
    }
})
export default class Quotes extends Vue {
    title!: string
    text!: string
    img!: string
}
