import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12748b92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "hero__box" }
const _hoisted_4 = { class: "hero__box__text" }
const _hoisted_5 = { class: "sky-blue-box only-desktop" }
const _hoisted_6 = { class: "image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  key: 0,
  class: "border-mid"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "hero__box" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "container" }
const _hoisted_14 = { class: "sky-blue-box only-mobile" }
const _hoisted_15 = { class: "image" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = {
  key: 0,
  class: "border-mid"
}
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueCard = _resolveComponent("BlueCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('home.section1.welcome.h1')), 1),
            _createElementVNode("h2", null, _toDisplayString(_ctx.$tm("home.section1.welcome.h2")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$tm("home.section1.welcome.p")), 1),
            _createElementVNode("small", null, _toDisplayString(_ctx.$tm("home.section1.welcome.small")), 1)
          ]),
          _createVNode(_component_BlueCard, { class: "only-mobile" }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                src: require('@/assets/images/sectionImage2.png'),
                class: "farmer"
              }, null, 8, _hoisted_7)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('home.section1.skyBlueBox'), (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "zone",
                key: item['id']
              }, [
                _createElementVNode("img", {
                  src: require('@/assets/images/' + item['imgSrc']),
                  alt: item['imgAlt']
                }, null, 8, _hoisted_8),
                (item['id'] == 2)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(item['p']), 1))
                  : _createCommentVNode("", true),
                (item['id'] != 2)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(item['p']), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("img", {
            src: require('@/assets/images/sectionImage2.png'),
            class: "box__img"
          }, null, 8, _hoisted_12)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("img", {
            src: require('@/assets/images/sectionImage2.png'),
            class: "farmer"
          }, null, 8, _hoisted_16)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('home.section1.skyBlueBox'), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "zone",
            key: item['id']
          }, [
            _createElementVNode("img", {
              src: require('@/assets/images/' + item['imgSrc']),
              alt: item['imgAlt']
            }, null, 8, _hoisted_17),
            (item['id'] == 2)
              ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(item['p']), 1))
              : _createCommentVNode("", true),
            (item['id'] != 2)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(item['p']), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}