
import { Vue, Options } from 'vue-class-component';
@Options({
    props: {
        title: '',
        text: '',
        author: ''
    }
})
export default class Quotes extends Vue {
    title!: string
    text!: string
    author!: string
}
