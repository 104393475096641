import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d71e6b2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass({ active: index + 1 == _ctx.activeTab }),
            onClick: ($event: any) => (_ctx.changeTab(index))
          }, _toDisplayString(tab), 11, _hoisted_2))
        }), 128))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "content",
        key: index
      }, [
        (index + 1 === _ctx.activeTab)
          ? (_openBlock(), _createElementBlock("div", { key: index }, [
              _renderSlot(_ctx.$slots, `tabcontent-${index + 1}`, {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}