
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';

import Hero from './sections/Hero.vue';
import SectionImage from '@/components/SectionImage.vue'


@Options({
    components: {
        Hero,
        SectionImage
    }
})
export default class NonPatients extends Vue {
    meta = setup(() => useMeta(
        computed(() => ({
            title: 'PAH Companion withMe | Official PAHC Website',
            description: 'PAH Companion withMe | Official PAHC Website'
        }))
    ))
}
