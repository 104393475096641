import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 2,
  class: "imageSide imgRightBottom"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 3,
  class: "imageSide imgRightBottom videoClick"
}
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalVideo = _resolveComponent("ModalVideo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sectionImg", { 'sectionImgRight': _ctx.imgRight }])
  }, [
    _createVNode(_component_ModalVideo, { ref: "modalVideo" }, null, 512),
    (!_ctx.videoClick)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["imageSide", { 'imgRightTop': _ctx.imgRight }])
        }, [
          _createElementVNode("img", {
            src: require('@/assets/images/' + _ctx.img),
            alt: ""
          }, null, 8, _hoisted_1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.videoClick)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["imageSide", { 'imgRightTop': _ctx.imgRight }])
        }, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.modalVideo.open())),
            class: "btn"
          }, [
            _createElementVNode("img", {
              src: require('@/assets/images/' + _ctx.img),
              alt: ""
            }, null, 8, _hoisted_2)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["text-side", { 'noLink': !_ctx.linkText && !_ctx.small, 'noPadding' : _ctx.noPadBot }])
    }, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", {
        class: _normalizeClass(["text-side__text", {'text-side__textSmall' : _ctx.small}]),
        innerHTML: _ctx.text
      }, null, 10, _hoisted_3),
      (_ctx.small)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.small), 1))
        : _createCommentVNode("", true),
      (_ctx.linkHref)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: _ctx.linkHref,
              href: _ctx.linkHref,
              class: "section-link"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.linkText), 1),
                _createElementVNode("img", {
                  src: require('@/assets/images/' + _ctx.linkImg),
                  alt: "",
                  loading: "lazy"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            }, 8, ["to", "href"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.extLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("a", {
              href: _ctx.extLink,
              class: "section-link",
              target: "_blank"
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.linkText), 1),
              _createElementVNode("img", {
                src: require('@/assets/images/' + _ctx.linkImg),
                alt: "",
                loading: "lazy"
              }, null, 8, _hoisted_9)
            ], 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.imgRight && !_ctx.videoClick)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("img", {
            src: require('@/assets/images/' + _ctx.img),
            alt: "",
            loading: "lazy"
          }, null, 8, _hoisted_11)
        ]))
      : _createCommentVNode("", true),
    (_ctx.imgRight && _ctx.videoClick)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.modalVideo.open())),
            class: "btn"
          }, [
            _createElementVNode("img", {
              src: require('@/assets/images/' + _ctx.img),
              alt: "",
              loading: "lazy"
            }, null, 8, _hoisted_13)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}