
import { Vue, Options } from 'vue-class-component';
import BlueCard from '@/views/home/sections/BlueCard.vue';
@Options({
    components: {
        BlueCard
    }
})
export default class Hero extends Vue {

}
